import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import { useViewPortQuery } from "src/core/hooks/useViewPortQuery";
import { MenuItemModal } from "src/modules/menuItemModal/MenuItemModal";
import { PrintPageBreak } from "src/modules/printPageBreak/PrintPageBreak";
import { MenuItemRow } from "src/pages/menuPage/components/MenuItemRow";
import { MenuItem, MenuItemsCategory } from "src/types/types";

const { Content } = Layout;

type Props = {
  menuItemsCategories?: MenuItemsCategory[];
  ordersEnabled?: boolean;
};

export const MenuItemsBlock = (props: Props) => {
  const { menuItemsCategories, ordersEnabled } = props;

  const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItem>();
  const [showMenuItemModal, setShowMenuItemModal] = useState(false);

  const isMobile = useViewPortQuery() === "mobile";

  useEffect(() => {
    (async () => {})();
  }, []);

  const onMenuItemClick = (menuItem: MenuItem) => {
    setSelectedMenuItem(menuItem);
    setShowMenuItemModal(true);
  };

  return (
    <React.Fragment>
      <MenuItemModal
        activeState={[showMenuItemModal, setShowMenuItemModal]}
        menuItem={selectedMenuItem}
      />

      {menuItemsCategories?.map((group, index) => {
        return (
          <Content
            key={`menu-category-content-${index}`}
            className={
              index % 2 !== 0 ? "category-items-1" : "category-items-2"
            }
            style={{
              textAlign: "center",

              padding: "20px",
              maxWidth: isMobile ? 400 : 600,
              margin: "0 auto",
            }}
          >
            {group.parentCategory && (
              <Content style={{ marginBottom: 20 }}>
                <h2
                  className="parent-category-title"
                  id={`${group.parentCategory.id}-${group.parentCategory.title}`}
                  style={{
                    marginBottom:
                      (group?.parentCategory?.description || "") !== "" ? 0 : 5,
                    lineHeight:
                      (group?.parentCategory?.description || "") !== ""
                        ? "25px"
                        : "inherit",
                  }}
                >
                  {group?.parentCategory?.title}
                </h2>
                {(group?.parentCategory?.description || "") !== "" && (
                  <span
                    className="parent-category-description"
                    style={{ marginBottom: 0, marginTop: -5 }}
                  >
                    {group?.parentCategory?.description}
                  </span>
                )}
              </Content>
            )}

            {group.hideHeader ? (
              <Content style={{ height: 20 }}>{""}</Content>
            ) : (
              <Content style={{}}>
                {group.hasParent ? (
                  <h3
                    id={`${group.categoryId}-${group.title}`}
                    className="child-category-title"
                    style={{ marginBottom: 5 }}
                  >
                    {group.title}
                  </h3>
                ) : (
                  <h2
                    id={`${group.categoryId}-${group.title}`}
                    className="category-title"
                    style={{ marginBottom: 5 }}
                  >
                    {group.title}
                  </h2>
                )}

                <span
                  className="category-description"
                  style={{ marginBottom: 0 }}
                >
                  {group.description}
                </span>
                <hr
                  className="under-category-line"
                  style={{ maxWidth: "40px", marginBottom: "20px" }}
                />
              </Content>
            )}

            {group.menuItems.map((item, index) => {
              return (
                <Content
                  key={`me-div-${index}`}
                  style={{
                    width:
                      window.innerWidth > 319 && group.twoColumns
                        ? "45%"
                        : "100%",
                    display:
                      window.innerWidth > 319 && group.twoColumns
                        ? "inline-block"
                        : "block",
                    minHeight:
                      window.innerWidth > 319 && group.twoColumns
                        ? 50
                        : "unset",
                    float:
                      window.innerWidth > 319 && group.twoColumns
                        ? "left"
                        : "initial",
                    marginRight: window.innerWidth < 325 ? 10 : 15,
                  }}
                >
                  <MenuItemRow
                    key={`me-i-${index}`}
                    index={index}
                    item={item}
                    twoColumns={
                      window.innerWidth > 319 && (group.twoColumns ?? false)
                    }
                    onClickEnabled={ordersEnabled}
                    onClick={() => {
                      onMenuItemClick(item);
                    }}
                  />
                </Content>
              );
            })}
            {group.bottomNote ? (
              <Content
                style={{
                  width: "100%",
                  minHeight: 50,
                  display: "inline-flex",
                  textAlign: "left",
                }}
                dangerouslySetInnerHTML={{ __html: group.bottomNote }}
              ></Content>
            ) : (
              <Content
                style={{ width: "100%", height: 0, display: "inline-flex" }}
              ></Content>
            )}
            <PrintPageBreak variant="after" />
          </Content>
        );
      })}
    </React.Fragment>
  );
};
