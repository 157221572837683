import React from "react";
import "src/modules/printPageBreak/component.styles.css";

interface IPrintPageBreak {
  variant: "before" | "after";
}

export const PrintPageBreak: React.FC<IPrintPageBreak> = ({ variant }) => {
  return (
    <div
      className={variant === "after" ? "page-break-after" : "page-break-before"}
    ></div>
  );
};
